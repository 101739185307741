<template>
  <span v-if="hasPerm('youth.add_seanceinscription')">
    <b-modal dialog-class="modal-xl"
      id="bv-modal-multi-inscriptions-quick"
      ok-title="Fermer"
      ok-variant="secondary"
      ok-only
      @hide="onClose"
    >
      <template v-slot:modal-title>
        <b><i :class="youthHomeIcon"></i>
          Inscriptions - {{ youthHome.name }} - {{ seanceDate | dateToString('dddd LL') }}
        </b>
      </template>
      <div class="multi-inscription-quick-modal">
        <b-row>
          <b-col cols="3" class="col-param">
            <div class="section">
              <input
                type="text"
                v-model="nameFilter"
                id="name-filter"
                class="form-control"
                :disabled="isLoading(loadingName)"
                placeholder="Entrez un nom ou prénom"
              />
            </div>
            <div v-if="!isLoading(loadingName) && filteredIndividuals.length === 0" class="empty-text">
              Aucun membre ne correspond à ce nom
            </div>
            <div class="individuals-list">
              <div
                class="individual-line"
                :class="{'selected' : isIndividualSelected(individual) }"
                v-for="individual of filteredIndividuals"
                :key="individual.id"
              >
                <div v-if="individual.entities.length >= 1">
                  <a href @click.prevent="selectIndividual(individual, individual.entities[0])">
                   {{ individual.lastAndFirstName() }}
                  </a>
                </div>
                <div v-if="isIndividualSelected(individual) && individual.entities.length > 1">
                  <span v-for="entity of individual.entities" :key="entity.id">
                    <a
                      href @click.prevent="selectIndividual(individual, entity)"
                      class="family-select"
                      :class="(isEntitySelected(individual, entity)) ? 'selected-entity' : ''"
                    >
                     Famille {{ entity.name }}
                    </a>
                  </span>
                </div>
                <span v-if="isAlreadyIn(individual)" class="badge badge-dark">déjà inscrit</span>
              </div>
            </div>
          </b-col>
          <b-col>
            <loading-gif :loading-name="loadingName"></loading-gif>
            <div v-if="fakeElement && !isLoading(loadingName)">
              <div class="field-line">
                <b-row>
                  <b-col><b>Cocher les séances pour lesquelles inscrire</b></b-col>
                  <b-col class="text-right">
                    <a href class="btn btn-sm btn-secondary" @click.prevent="onCancel()">Annuler</a>
                    &nbsp;
                    <a
                      href
                      class="btn btn-sm btn-primary"
                      :class="{ disabled: selectedIndividuals.length === 0, }"
                      @click.prevent="onSave()"
                    >
                      Enregistrer
                    </a>
                  </b-col>
                </b-row>
              </div>
              <div class="error-text" v-if="errorText">{{ errorText }}</div>
              <div class="label-line field-line">
                <b-row>
                  <b-col cols="4">
                  </b-col>
                  <b-col>
                    <seance-clocking
                      :element="fakeElement"
                      :seance-type="seanceType"
                      :day="day"
                      :youth-home="youthHome"
                      :period="period"
                      :seances="mainSeances"
                      :rules="rules"
                      label
                      no-mark
                      @seances-updated="onSeancesUpdated(fakeElement, $event)"
                      @seance-clicked="onColSelected"
                    >
                    </seance-clocking>
                  </b-col>
                </b-row>
              </div>
              <div v-if="selectedIndividuals.length === 0" class="empty-text">
                Veuillez sélectionner un ou plusieurs membres.<br />
                Les membres sélectionnés seront automatiquement inscrit sur les séances cochés
              </div>
              <div v-for="element of this.selectedIndividuals" :key="element.individual.id" class="field-line">
                <b-row>
                  <b-col cols="4">
                    {{ element.individual.lastAndFirstName() }}
                  </b-col>
                  <b-col>
                    <seance-clocking
                      :element="element"
                      :seance-type="seanceType"
                      :day="day"
                      :youth-home="youthHome"
                      :period="period"
                      :seances="mainSeances"
                      :rules="rules"
                      @seances-updated="onSeancesUpdated(element, $event)"
                      @seance-clicked="onSeanceChecked"
                    >
                    </seance-clocking>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import SeanceClocking from '@/components/Youth/SeanceInscriptionList/SeanceClocking'
import LoadingGif from '@/components/Controls/LoadingGif'
import { BackendMixin } from '@/mixins/backend'
import { makeEntity, makeIndividual } from '@/types/people'
import { existsIn } from '@/utils/arrays'
import { BackendApi } from '@/utils/http'
import { includes } from '@/utils/strings'
import store from '@/store'

export default {
  name: 'multi-inscriptions-quick-modal',
  components: {
    SeanceClocking,
    LoadingGif,
  },
  mixins: [BackendMixin],
  props: {
    day: String,
    elements: Array,
    allMembers: Array,
    membersLoaded: Boolean,
    seanceType: Object,
    youthHome: Object,
    period: Object,
    seances: Array,
    rules: Array,
  },
  data() {
    return {
      selectedIndividuals: [],
      errorText: '',
      infoText: '',
      loadingName: 'multi-seances-inscriptions',
      seancesCreationLoading: 'multi-seances-inscriptions-create',
      nameFilter: '',
      allInscriptions: [],
      fakeElement: null,
      mainManager: null,
      managers: new Map(),
    }
  },
  computed: {
    mainSeances() {
      return this.seances
    },
    youthHomeIcon() {
      return store.getters.youthHomeIcon
    },
    seanceDate() {
      return moment(this.day).toDate()
    },
    filteredIndividuals() {
      return this.allMembers.filter(
        elt => {
          if (!this.nameFilter) {
            return true
          } else {
            return includes(elt.lastAndFirstName(), this.nameFilter)
          }
        }
      )
    },
    isToday() {
      return moment(this.day).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
    },
  },
  watch: {
    allMembers: function() {},
    youthHome: function() {},
    elements: function(newValue) {
      this.allInscriptions = newValue
    },
    rules: function() {},
    membersLoaded: function(value) {
      if (!value) {
        this.startLoading(this.loadingName)
      } else {
        this.endLoading(this.loadingName)
      }
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    onClose() {
      this.$emit('close', {})
    },
    async selectIndividual(individual, entity) {
      const index = this.selectedIndividuals.map(elt => elt.individual.id).indexOf(individual.id)
      const entitySeances = this.getEntitySeances(individual).map(elt => elt.clone())
      if (index < 0) {
        this.selectedIndividuals.push(
          {
            individual: individual,
            entity: entity,
            entitySeances: entitySeances,
          }
        )
      } else {
        const ind = this.selectedIndividuals[index]
        this.selectedIndividuals.splice(index, 1)
        this.selectedIndividuals = [].concat(this.selectedIndividuals)
        if (ind.entity.id !== entity.id) {
          this.selectedIndividuals.push(
            {
              individual: individual,
              entity: entity,
              entitySeances: entitySeances,
            }
          )
          this.selectedIndividuals = [].concat(this.selectedIndividuals)
        }
      }
    },
    isAlreadyIn(individual) {
      const index = this.allInscriptions.map(elt => elt.individual.id).indexOf(individual.id)
      if (index >= 0) {
        return this.allInscriptions[index].inscriptions.length > 0
      }
      return false
    },
    async onInscriptionsChanged() {
    },
    getEntitySeances(individual) {
      const index = this.elements.map(elt => elt.individual.id).indexOf(individual.id)
      if (index >= 0) {
        return this.elements[index].entitySeances
      }
      return []
    },
    onCancel() {
      this.selectedIndividuals = []
    },
    async onSave() {
      this.startLoading(this.loadingName)
      this.errorText = ''
      const selectedIndividuals = this.selectedIndividuals.concat([])
      this.selectedIndividuals = []
      for (const elt of selectedIndividuals) {
        const data = []
        const individual = elt.individual
        let entity = this.getSelectedEntity(individual)
        if (entity === null) {
          entity = elt.entity
        } else {
          elt.entity = entity
        }
        for (const seance of elt.entitySeances) {
          if (seance.isIndividualInscriptionNew(individual.id)) {
            data.push(
              {
                seance: seance.id,
                individual: individual.id,
                cancelled: false,
              }
            )
          }
          if (seance.isIndividualInscriptionCancelled(individual.id)) {
            data.push(
              {
                seance: seance.id,
                individual: individual.id,
                cancelled: true,
                refund: true, // l'inscription annulée est remboursée.
                hide: true, // l'inscription annulée ne sera pas affichée sur la fiche famille.
              }
            )
          }
        }
        if (data.length) {
          let url = '/api/youth/entity-create-inscriptions/' + entity.id + '/'
          let backendApi = new BackendApi('post', url)
          try {
            await backendApi.callApi(data)
          } catch (err) {
            this.selectedIndividuals.push(elt)
            this.errorText = this.getErrorText(err)
          }
        }
      }
      this.endLoading(this.loadingName)
      this.$emit('refreshed', {})
    },
    isIndividualSelected(individual) {
      return existsIn([individual.id], this.selectedIndividuals.map(elt => elt.individual.id))
    },
    isEntitySelected(individual, entity) {
      const selection = this.selectedIndividuals.filter(elt => elt.individual.id === individual.id)
      if (selection.length) {
        return selection[0].entity.id === entity.id
      }
      return false
    },
    getSelectedEntity(individual) {
      const selection = this.selectedIndividuals.filter(elt => elt.individual.id === individual.id)
      if (selection.length) {
        return selection[0].entity
      }
      return null
    },
    onSeancesUpdated(elt, event) {
      if (elt.individual.id) {
        if (this.mainManager) {
          const individualManager = event.manager
          if (individualManager) {
            if (individualManager.newInscriptions().length === 0) {
              for (const mainSeance of this.mainManager.newInscriptions()) {
                const index2 = this.mainManager.getSeances().map(elt => elt.id).indexOf(mainSeance.seance.id)
                if (index2 >= 0) {
                  const entitySeance = individualManager.getSeances()[index2]
                  individualManager.setIndividualInscription(entitySeance, elt.individual)
                }
              }
              elt.entitySeances = event.manager.getSeances()
            }
          }
        }
      } else {
        this.mainManager = event.manager
        elt.entitySeances = event.seances
      }
    },
    onSeanceChecked(event) {
    },
    onColSelected(event) {
      this.mainManager = event.manager
    },
    init() {
      this.fakeElement = {
        individual: makeIndividual(),
        entity: makeEntity(),
        entitySeances: [],
      }
      this.startLoading(this.loadingName)
    },
  },
  created() {
    this.init()
    this.allInscriptions = this.elements
  },
}
</script>
<style lang="less">
.multi-inscription-quick-modal {
  .individuals-list {
    height: 300px;
    overflow-y: scroll;
  }

  .inscription-line, .individual-line {
    padding: 1px;
    font-size: 12px;
    border-bottom: solid 1px #f0f0f0;
    margin-bottom: 2px;
  }

  .individual-line a {
    color: #000 !important;
  }

  .individual-line.selected {
    background: #222;
    color: #fff !important;
  }

  .individual-line.selected a {
    color: #fff !important;
  }

  .individual-line:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
  }

  .error-text {
    padding: 5px;
    color: #000;
    background: #f78888;
  }

  .info-text {
    padding: 5px;
    color: #000;
    background: #7db093;
  }

  .col-param .section {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: solid 1px #e0e0e0;
  }

  .checkbox-holder {
    position: relative;
    display: inline-block;
  }

  .checkbox-holder input[type="checkbox"] {
    position: absolute;
    opacity: 0.01;
    top: 4px;
    left: 2px;
  }

  .checkbox-holder input[type="checkbox"] {
    position: absolute;
    opacity: 0.01;
    top: 4px;
    left: 2px;
  }

  .checkbox-holder input[type="checkbox"] + span:before {
    font: 14pt 'Font Awesome 6 Free';
    content: '\f096';
    display: inline-block;
    width: 14pt;
    padding: 2px 0 0 3px;
    margin-right: 0.5em;
  }

  .checkbox-holder input[type="checkbox"]:checked + span:before {
    content: '\f046';
  }

  .checkbox-holder input[type="checkbox"]:focus + span:before {
    outline: 1px dotted #aaa;
  }

  .checkbox-holder input[type="checkbox"]:disabled + span {
    color: #aaa;
    cursor: not-allowed;
  }

  .line-header {
    padding: 2px;
    background: #e0e0e0;
    color: #222;
    margin: 5px 0;
  }

  .dark-mode {
    .line-header {
      background: #333;
      color: #fff;
    }
  }

  .label-line {
    background: #ccc;
  }
  .family-select {
    text-decoration: underline;
    display: inline-block;
    margin: 1px 3px;
    padding: 1px 4px;
  }
  a.selected-entity {
    background: #888;
  }
}
</style>

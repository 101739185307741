<template>
  <span>
    <span v-if="clockingSeance">
      <seance-clocking
        :element="element"
        :seance-type="seanceType"
        :day="day"
        :youth-home="youthHome"
        :period="seancePeriod"
        :seances="seances"
        :rules="rules"
        :check-seance="checkSeance"
        :name-before="nameBefore"
        @seances-updated="onSeancesUpdated"
        @seance-checkked="onSeanceChecked"
      >
      </seance-clocking>
      <absence-clocking
        v-if="showAbsence"
        :element="element"
        :seances="seances"
        :inscriptions="getSelectedInscriptions(element)"
        @refreshed="onRefreshInscription(element, $event)"
      >
      </absence-clocking>
    </span>
    <span v-else>
      <div
        v-for="inscription in getSelectedInscriptions(element)"
        :key="inscription.id"
        class="inscription-line small"
        :class="{ 'has-absence': inscription.absence, }"
      >
        <span v-if="nameBefore">
          <a
            href
            @click.prevent="onSeanceClicked(element, inscription)"
            :class="getSeanceStyle(inscription)"
          >
            {{ inscription.seance.getShortName() }}
            <span v-if="inscription.multiplier > 1">X {{ inscription.multiplier }}</span>
          </a>
          <span class="absence-indicator-holder">
            <absence-indicator
             :inscription="inscription"
             :individual="element.individual"
             @updated="onRefreshInscription(element, $event)"
            ></absence-indicator>
          </span>
        </span>
        <span v-if="hasOpeningHours">
          <time-selector
            :inscription="inscription"
            :opening-hours="openingHours"
            :clock-status="clockStatus"
            @prefixClicked="prefixClicked"
            @clocked="onClocked"
          >
          </time-selector>
        </span>
        <span v-else-if="showMomentSelector">
          <presence-moment
            :inscriptions="element.inscriptions"
            :day="day"
            :seanceType="seanceType.id"
            :seancePeriod="seancePeriod.id"
            :youthHome="youthHome.id"
            :individual="element.individual"
            :presents="getPresences(element.individual.id)"
            :show-evening="showEvening"
          ></presence-moment>
        </span>
        <span v-if="!nameBefore">
          <span class="absence-indicator-holder">
            <absence-indicator
             :inscription="inscription"
             :individual="element.individual"
             @updated="onRefreshInscription(element, $event)"
            ></absence-indicator>
          </span>
          <a
            href
            @click.prevent="onSeanceClicked(element, inscription)"
            :class="getSeanceStyle(inscription)"
          >
            {{ inscription.seance.getShortName() }}
            <span v-if="inscription.multiplier > 1">X {{ inscription.multiplier }}</span>
          </a>
        </span>
        <div>
          <span
            class="badge badge-dark"
            v-for="workshopInscription of inscription.seance.getWorkshopInscriptions(element.individual)"
            :key="workshopInscription.workshop"
          >
            {{ inscription.seance.getWorkshop(workshopInscription.workshop).name }}
          </span>
        </div>
      </div>
    </span>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import TimeSelector from '@/components/Seances/TimeSelector'
import AbsenceIndicator from '@/components/Youth/AbsenceIndicator'
import PresenceMoment from '@/components/Youth/PresenceMoment'
import { BackendMixin } from '@/mixins/backend'
import SeanceClocking from '@/components/Youth/SeanceInscriptionList/SeanceClocking'
import AbsenceClocking from '@/components/Youth/SeanceInscriptionList/AbsenceClocking'

export default {
  name: 'InscriptionClocking',
  mixins: [BackendMixin],
  components: {
    SeanceClocking,
    TimeSelector,
    AbsenceIndicator,
    PresenceMoment,
    AbsenceClocking,
  },
  props: {
    element: Object,
    seances: Array,
    selectedSeances: Array,
    showOnlySelectedSeances: Boolean,
    canSwitch: Boolean,
    clockStatus: Number,
    hasOpeningHours: Boolean,
    openingHours: Object,
    day: String,
    showMomentSelector: Boolean,
    presencesMap: Map,
    youthHome: Object,
    seanceType: Object,
    seancePeriod: Object,
    showEvening: Boolean,
    rules: Array,
    checkSeance: Object,
    nameBefore: {
      type: Boolean,
      default: false,
    },
    showAbsence: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    }
  },
  watch: {
    element: function() {},
    seances: function() {},
    selectedSeances: function() {},
    clockStatus: function() {},
    hasOpeningHours: function() {},
  },
  computed: {
    hasSelectedSeances() {
      return this.seances.length > 0
    },
    clockingSeance() {
      return this.seanceType.clockingSeance && !this.hasOpeningHours
    },
  },
  methods: {
    getSelectedInscriptions(elt) {
      let inscriptions = []
      if ((!this.hasSelectedSeances) || (!this.showOnlySelectedSeances)) {
        return elt.inscriptions
      } else {
        const selectedSeanceIds = this.selectedSeances.map(seance => seance.id)
        return elt.inscriptions.filter(inscription => selectedSeanceIds.indexOf(inscription.seance.id) >= 0)
      }
    },
    onSeanceClicked(element, inscription) {
      this.$emit('seanceClicked', { element: element, inscription: inscription, })
    },
    onRefreshInscription(element, inscription) {
      this.$emit('refreshed', { individual: element.individual, inscription: inscription, })
    },
    getSeanceStyle(inscription) {
      let style = ''
      if (inscription.absence) {
        style = 'ins-cancelled '
      }
      if (!this.canSwitch) {
        style += 'disabled-link'
      }
      return style
    },
    getPresences(id) {
      if (this.presencesMap.has(id)) {
        return this.presencesMap.get(id)
      } else {
        return [false, false, false, false]
      }
    },
    prefixClicked() {
      this.$emit('prefixClicked')
    },
    onSeancesUpdated(event) {
      this.$emit('seances-updated', event)
    },
    onSeanceChecked(event) {
      this.$emit('seance-checked', event)
    },
    onClocked(event) {
      this.$emit('clocked', event)
    },
  },
}
</script>
<style lang="less">
a.disabled-link {
  color: #222 !important;
  cursor: default;
}
.dark-mode a.disabled-link {
  color: #ccc !important;
}
a.disabled-link:hover {
  text-decoration: none;
}
.absence-indicator-holder {
  display: inline-block;
  margin-top: 4px;
  margin-left: 4px;
}
.ins-cancelled, a.ins-cancelled:hover {
  text-decoration: line-through;
}
.inscription-line.has-absence {
  background: #fad4d4;
}
</style>
